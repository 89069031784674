/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/Layout'
import NewsSnippet from '../components/NewsSnippet'

import '../components/news.css'

const createPaginationObjects = (length, currentPage, increment = 2) => Array.from({ length }, (_, i) => ({
  link: `/aktualnosci/${i + increment}/`,
  index: i + increment,
  current: currentPage === i + increment,
}))

class NewsList extends Component {
  render() {
    const { data } = this.props
    const posts = data.allMarkdownRemark.edges
    const { currentPage, numPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()

    // Create the navigation link
    let navItems = [
      {
        link: '/aktualnosci',
        index: 1,
        current: currentPage === 1,
      },
    ]
    if (numPages <= 5) {
      navItems = [
        ...navItems,
        ...Array.from({ length: numPages - 1 }, (_, i) => ({
          link: `/aktualnosci/${i + 2}/`,
          index: i + 2,
          current: currentPage === i + 2,
        })),
      ]
    } else {
      // We have a situation where we have to show the first
      // item, three items around the current one
      // and also the last item
      /* eslint-disable no-lonely-if */
      if (currentPage <= 3) {
        // If the current one is closer to the start
        navItems = [
          ...navItems,
          ...createPaginationObjects(3, currentPage),
          {
            separator: true,
            index: 'starter-separator',
          },
          {
            link: `/aktualnosci/${numPages}/`,
            index: numPages,
            current: false,
          },
        ]
      } else if (currentPage > numPages - 3) {
        // If the current one is closer to the last one
        navItems = [
          ...navItems,
          {
            separator: true,
            index: 'finisher-separator',
          },
          ...createPaginationObjects(4, currentPage, numPages - 3),
        ]
      } else {
        navItems = [
          ...navItems,
          {
            separator: true,
            index: 'starter-separator',
          },
          ...createPaginationObjects(3, currentPage, currentPage - 1),
          {
            separator: true,
            index: 'finisher-separator',
          },
          {
            link: `/aktualnosci/${numPages}/`,
            index: numPages,
            current: false,
          },
        ]
      }
      /* eslint-enable */
    }

    return (
      <div>
        <SEO title="Aktualności" pathname={this.props.location.pathname} />
        <Layout>
          <div className="news">
            <h1>Aktualności</h1>
            {posts.map(edge => (
              <NewsSnippet
                key={edge.node.id}
                linkDestination={edge.node.fields.slug}
                image={edge.node.frontmatter.image}
                imgAlt={edge.node.id}
                title={edge.node.frontmatter.title}
                shortDescription={edge.node.frontmatter.short_description}
              />
            ))}
          </div>
          {(!isFirst || !isLast) && (
          <div className="news__pagination">
            <div className="news_pagination-nav">
              {!isFirst && (
              <Link className="news__pagination-link" to={`/aktualnosci/${prevPage}`} rel="prev">
                ← Poprzednia strona
              </Link>
              )}
            </div>
            <ul className="news__pagination-pages">
              {navItems.map(item => (
                <li className="news__pagination-pages-item" key={item.index}>
                  {item.separator ? (
                    <span className="news__pagination-pages--ellipsis">
                      &hellip;
                    </span>
                  ) : (
                    <Link to={item.link} className={`news__pagination-link${item.current ? '--current' : ''}`}>
                      {item.index}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
            <div className="news_pagination-nav">
              {!isLast && (
              <Link className="news__pagination-link" to={`/aktualnosci/${nextPage}`} rel="next">
              Następna Strona →
              </Link>
              )}
            </div>
          </div>
          )}
        </Layout>
      </div>
    )
  }
}

export default NewsList

export const query = graphql`
  query newsListQuery($skip: Int!, $limit: Int!){
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date]}
      filter: {frontmatter: {templateKey: {eq: "news-post" }}}
      limit: $limit
      skip: $skip) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            short_description
            description
            image {
              childImageSharp {
                fluid(maxWidth: 500, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            date
          }
        }
      }
    }
  }
`
